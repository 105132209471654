<template>
  <div>
    <!--table university-->
    <ek-table
      :columns="columns"
      :items="universities"
      @delete-selected="deleteUni"
      @details="detailsAppPanel"
    >
      <template slot="items.dateCreated" slot-scope="{ value }">
        <span> {{ new Date(value).toLocaleDateString() }}</span>
      </template>
    </ek-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  components: {},
  computed: {
    ...mapState({
      columns: (state) => state.university.columns,
    }),
    ...mapGetters(["universities"]),
  },
  methods: {
    ...mapActions(["getUniversityList", "getFacultyName"]),
    detailsAppPanel({ row }) {
      this.$store.commit("IS_DIALOG_OPEN", true);
      this.$store.commit("SET_UNIVERSITY_DTO", row);
    },
    deleteUni(e) {
      this.$store.dispatch("deleteUniversities", e);
    },
  },
  created() {
    this.getUniversityList();
    this.getFacultyName();
  },
};
</script>
